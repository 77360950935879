// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
// import useJwt from '@src/auth/jwt/useJwt'
// const config = useJwt.jwtConfig
// const headers = {
//     token: JSON.parse(localStorage.getItem(config.storageTokenKeyName)),
//     userID: JSON.parse(localStorage.getItem('userID'))
//     }
const baseURL = `${process.env.REACT_APP_API_BASE_URL}`
export const getData = createAsyncThunk('appAdminItems/getData', async (params) => {
  const response = await axios.post(`${baseURL}/Item/GetItemsData`, params, {mode:'cors'})
  return {
    params,
    data: response.data.data,
    allData: response.data.allData,
    totalPages: response.data.total
  }
})

export const getEditData = createAsyncThunk('appAdminItems/getEditData', async (params) => {
  const response = await axios.post(`${baseURL}/Item/GetItemsData`, params, {mode:'cors'})
  return {
      params,
      data: response.data.data,
      allData: response.data.allData,
      totalPages: response.data.total
  }
})

export const resetEditData = createAsyncThunk('appCircle/resetEditData', async () => {
  return {
      data: []
  }
})

export const EditCircle = createAsyncThunk('appCircle/Update', async (params) => {
    const response = await axios.post(`${baseURL}/Circle/Update`, params, {mode:'cors'})
    return response
})

export const setEditID = createAsyncThunk('appAdminItems/setEditID', async (id) => {
  return {
    data: id
  }
})

export const appCircleSlice = createSlice({
  name: 'appAdminItems',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    editID:0,
    editData:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
      })
      .addCase(setEditID.fulfilled, (state, action) => {
        
        state.editID = action.payload.data.id
      })
      .addCase(getEditData.fulfilled, (state, action) => {
        
        state.editData = action.payload.allData
      })
      .addCase(resetEditData.fulfilled, (state, action) => {
        state.editData = action.payload.data
        state.editID = 0
      })
  }
})
  
export default appCircleSlice.reducer
