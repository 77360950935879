// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
// import useJwt from '@src/auth/jwt/useJwt'
// const config = useJwt.jwtConfig
// const headers = {
//     token: JSON.parse(localStorage.getItem(config.storageTokenKeyName)),
//     userID: JSON.parse(localStorage.getItem('userID'))
//     }
const baseURL = `${process.env.REACT_APP_API_BASE_URL}`
export const getBorrowData = createAsyncThunk('appDriverBookingExpense/getBorrowRequest', async (params) => {
  const response = await axios.post(`${baseURL}/RequestItem/GetBorrowRequest`, params, {mode:'cors'})
  return {
    params,
    data: response.data
  }
})

export const getRentData = createAsyncThunk('appDriverBookingExpense/getRentRequest', async (params) => {
  const response = await axios.post(`${baseURL}/RequestItem/GetRentRequest`, params, {mode:'cors'})
  return {
    params,
    data: response.data
  }
})

export const getBuyData = createAsyncThunk('appDriverBookingExpense/getBuyRequest', async (params) => {
  const response = await axios.post(`${baseURL}/RequestItem/GetBuyRequest`, params, {mode:'cors'})
  return {
    params,
    data: response.data
  }
})

export const getBorrowItemDetail = createAsyncThunk('appItem/getBorrowItemDetail', async (params) => {
  const response = await axios.post(`${baseURL}/Item/GetItemDetail`, params, {mode:'cors'})
  return {
    params,
    data: response.data
  }
})

export const getBuyItemDetail = createAsyncThunk('appItem/getBuyItemDetail', async (params) => {
  const response = await axios.post(`${baseURL}/Item/GetItemDetail`, params, {mode:'cors'})
  return {
    params,
    data: response.data
  }
})

export const getRentItemDetail = createAsyncThunk('appItem/getRentItemDetail', async (params) => {
  const response = await axios.post(`${baseURL}/Item/GetItemDetail`, params, {mode:'cors'})
  return {
    params,
    data: response.data
  }
})

export const resetItemDetail = createAsyncThunk('appItem/resetItemDetail', async () => {
  
  return {
    data: []
  }
})

export const ApproveorRejectBorrow = createAsyncThunk('appDriverBookingExpense/approveorRejectBorrow', async (params) => {
  const response = await axios.post(`${baseURL}/RequestItem/ApproveorRejectBorrow`, params, {mode:'cors'})
  return response
})

export const ApproveorRejectRent = createAsyncThunk('appDriverBookingExpense/approveorRejectRent', async (params) => {
  const response = await axios.post(`${baseURL}/RequestItem/ApproveorRejectRent`, params, {mode:'cors'})
  return response
})

export const ApproveorRejectBuy = createAsyncThunk('appDriverBookingExpense/approveorRejectBuy', async (params) => {
  const response = await axios.post(`${baseURL}/RequestItem/ApproveorRejectBuy`, params, {mode:'cors'})
  return response
})

export const updateBookingExpense = createAsyncThunk('appDriverBookingExpense/getDeclinedBookExpenses', async (params) => {
  const response = await axios.post(`${baseURL}/DriverBookingExpense/Update`, params, {mode:'cors'})
  return response
})

export const getDeclineExpenseData = createAsyncThunk('appDriverBookingExpense/getDeclinedBookExpenses', async (params) => {
  const response = await axios.post(`${baseURL}/DriverBookingExpense/GetDeclinedBookExpenses`, params, {mode:'cors'})
  return {
    params,
    data: response.data.data,
    allData: response.data.allData,
    totalPages: response.data.total
  }
})

export const getApprovedExpenseData = createAsyncThunk('appDriverBookingExpense/getApprovedBookExpenses', async (params) => {
  
  const response = await axios.post(`${baseURL}/DriverBookingExpense/GetApprovedBookExpenses`, params, {mode:'cors'})
  return {
    params,
    data: response.data.data,
    allData: response.data.allData,
    totalPages: response.data.total
  }
})

export const getPendingExpenseData = createAsyncThunk('appDriverBookingExpense/getPendingExpenseData', async (params) => {
  
  const response = await axios.post(`${baseURL}/DriverBookingExpense/GetPendingBookExpenses`, params, {mode:'cors'})
  return {
    params,
    // data: response.data.data,
    allData: response.data.allData
    // totalPages: response.data.total
  }
})


export const appDriverBookingExpenseSlice = createSlice({
  name: 'appDriverBookingExpense',
  initialState: {
    BorrowData:[],
    RentData:[],
    BuyData:[],
    approvedExpenseData: [],
    approvedExpenseTotal: 1,
    approvedExpenseParams: {},
    approvedExpenseAllData: [],
    declineExpenseData: [],
    declineExpenseTotal: 1,
    declineExpenseParams: {},
    declineExpenseAllData: [],
    pendingExpenseData: [],
    pendingExpenseTotal: 1,
    pendingExpenseParams: {},
    pendingExpenseAllData: [],
    getRentItemDetailData:[],
    getBorrowItemDetailData:[],
    getBuyItemDetailData:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getBorrowData.fulfilled, (state, action) => {
        
      state.BorrowData = action.payload.data
    })
    .addCase(getRentData.fulfilled, (state, action) => {
      
      state.RentData = action.payload.data
    })
    .addCase(getBuyData.fulfilled, (state, action) => {
      state.BuyData = action.payload.data
    })
    .addCase(getBorrowItemDetail.fulfilled, (state, action) => {
      state.getBorrowItemDetailData = action.payload.data
    })
    .addCase(getBuyItemDetail.fulfilled, (state, action) => {
      state.getBuyItemDetailData = action.payload.data
    })
    .addCase(getRentItemDetail.fulfilled, (state, action) => {
      state.getRentItemDetailData = action.payload.data
    })
    .addCase(resetItemDetail.fulfilled, (state, action) => {
      
      state.getBorrowItemDetailData = action.payload.data
      state.getBuyItemDetailData = action.payload.data
      state.getRentItemDetailData = action.payload.data
    })
      .addCase(getDeclineExpenseData.fulfilled, (state, action) => {
        
        state.declineExpenseData = action.payload.data
        state.declineExpenseAllData = action.payload.allData
        state.declineExpenseTotal = action.payload.totalPages
        state.declineExpenseParams = action.payload.params
      })


      .addCase(getApprovedExpenseData.fulfilled, (state, action) => {
        
        state.approvedExpenseData = action.payload.data
        state.approvedExpenseAllData = action.payload.allData
        state.approvedExpenseTotal = action.payload.totalPages
        state.approvedExpenseParams = action.payload.params
      })

      .addCase(getPendingExpenseData.fulfilled, (state, action) => {
        
        // state.pendingExpenseData = action.payload.data
        state.pendingExpenseAllData = action.payload.allData
        // state.pendingExpenseTotal = action.payload.totalPages
        state.pendingExpenseParams = action.payload.params
      })
      
  }
})

export default appDriverBookingExpenseSlice.reducer
