// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
// import useJwt from '@src/auth/jwt/useJwt'
// const config = useJwt.jwtConfig
// const headers = {
//     token: JSON.parse(localStorage.getItem(config.storageTokenKeyName)),
//     userID: JSON.parse(localStorage.getItem('userID'))
//     }
const baseURL = `${process.env.REACT_APP_API_BASE_URL}`
export const getEarningsDataList = createAsyncThunk('appEarnings/getEarnings', async (params) => {
  const response = await axios.post(`${baseURL}/Earnings/GetEarnings`, params)
  return response.data
})

export const getRentTranscationData = createAsyncThunk('appEarnings/getRentTranscation', async (params) => {
  const response = await axios.post(`${baseURL}/Earnings/GetRentTranscation`, params, {mode:'cors'})
  return {
      params,
      data: response.data.data,
      allData: response.data.allData,
      totalPages: response.data.total
  }
})

export const getBuyTranscationData = createAsyncThunk('appEarnings/getBuyTranscation', async (params) => {
  const response = await axios.post(`${baseURL}/Earnings/GetBuyTranscation`, params, {mode:'cors'})
  return {
      params,
      data: response.data.data,
      allData: response.data.allData,
      totalPages: response.data.total
  }
})


export const appEarningsSlice = createSlice({
  name: 'appEarnings',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    buyTranscationdata: [],
    buyTranscationtotal: 1,
    buyTranscationparams: {},
    buyTranscationallData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRentTranscationData.fulfilled, (state, action) => {
        
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
      })
      .addCase(getBuyTranscationData.fulfilled, (state, action) => {
        
            state.buyTranscationdata = action.payload.data
            state.buyTranscationallData = action.payload.allData
            state.buyTranscationtotal = action.payload.totalPages
            state.buyTranscationparams = action.payload.params
      })
  }
})
  
export default appEarningsSlice.reducer
