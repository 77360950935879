// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
// import useJwt from '@src/auth/jwt/useJwt'
// const config = useJwt.jwtConfig
// const headers = {
//     token: JSON.parse(localStorage.getItem(config.storageTokenKeyName)),
//     userID: JSON.parse(localStorage.getItem('userID'))
//     }
const baseURL = `${process.env.REACT_APP_API_BASE_URL}`
export const getRatingList = createAsyncThunk('appRating/GetItemRatings', async () => {
  const response = await axios.post(`${baseURL}/Rating/GetItemRatings`)
  return response.data
})

export const getItemsData = createAsyncThunk('appRating/getItemsData', async (params) => {
  const response = await axios.post(`${baseURL}/Item/GetItemsData`, params)
  return response.data
})

export const appRatingSlice = createSlice({
  name: 'appRating',
  initialState: {
    data: []
  },
  reducers: {}
})

export default appRatingSlice.reducer
