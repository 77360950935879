// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
// import useJwt from '@src/auth/jwt/useJwt'
// const config = useJwt.jwtConfig
// const headers = {
//     token: JSON.parse(localStorage.getItem(config.storageTokenKeyName)),
//     userID: JSON.parse(localStorage.getItem('userID'))
//     }
const baseURL = `${process.env.REACT_APP_API_BASE_URL}`
export const getCurrentUser = createAsyncThunk('appBorrowBucks/getCurrentUser', async (params) => {
  const response = await axios.post(`${baseURL}/User/GetCurrentUser`, params, {mode:'cors'})
  return response.data
})

export const getData = createAsyncThunk('appBorrowBucks/getData', async (params) => {
  const response = await axios.post(`${baseURL}/BorrowBucks/GetBorrowingMy`, params, {mode:'cors'})
  
  return {
      params,
      data: response.data.data,
      allData: response.data.allData,
      totalPages: response.data.total
  }
})

export const getMyBorrowingData = createAsyncThunk('appBorrowBucks/getMyBorrowing', async (params) => {
      
  const response = await axios.post(`${baseURL}/BorrowBucks/GetMyBorrowing`, params, {mode:'cors'})
  
  return {
      params,
      data: response.data.data,
      allData: response.data.allData,
      totalPages: response.data.total
  }
})

export const getBorrowBucksDataList = createAsyncThunk('appBorrowBucks/getBorrowBucks', async (params) => {
 
  const response = await axios.post(`${baseURL}/BorrowBucks/GetBorrowBucks`, params)
  return response.data
})

    //  export const userinfo = createAsyncThunk('appBorrowBucks/getBorrowBucks', async (params) => {
      
    //   const response = await axios.post('http:///BorrowBucks/GetBorrowBucks', params)
    //   return response.data
    // })


    export const appBorrowBucksSlice = createSlice({
      name: 'appBorrowBucks',
      initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        myBorrowingdata: [],
        myBorrowingtotal: 1,
        myBorrowingparams: {},
        myBorrowingallData: []
      },
      reducers: {},
      extraReducers: builder => {
        builder
          .addCase(getData.fulfilled, (state, action) => {
            
                state.data = action.payload.data
                state.allData = action.payload.allData
                state.total = action.payload.totalPages
                state.params = action.payload.params
          })
          .addCase(getMyBorrowingData.fulfilled, (state, action) => {
            
                state.myBorrowingdata = action.payload.data
                state.myBorrowingallData = action.payload.allData
                state.myBorrowingtotal = action.payload.totalPages
                state.myBorrowingparams = action.payload.params
          })
      }
    })
    
    export default appBorrowBucksSlice.reducer
