// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'

import Item from '../views/rebobu-views/Items/store'
import Circle from '../views/rebobu-views/circle/store'
import searchItem from '../views/rebobu-views/searchItems/store'
import requestItem from '../views/rebobu-views/requestItems/store'
import BorrowBucks from '../views/rebobu-views/borrowBucks/store'
import Earnings from '../views/rebobu-views/earnings/store'
import SubscriptionPlan from '../views/rebobu-views/subscriptionPlans/store'
import ItemDetail from '../views/rebobu-views/itemDetails/store'
import Users from '../views/rebobu-views/users/store'
import adminItems from '../views/rebobu-views/adminItems/store'
import claims from '../views/rebobu-views/claims/store'
import ratings from '../views/rebobu-views/ratings/store'
import itemSold from '../views/rebobu-views/itemSold/store'


const rootReducer = {
  auth,
  navbar,
  layout,
  Item,
  Circle,
  searchItem,
  requestItem,
  BorrowBucks,
  Earnings,
  SubscriptionPlan,
  ItemDetail,
  Users,
  adminItems,
  claims,
  ratings,
  itemSold
}

export default rootReducer
