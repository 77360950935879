// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import useJwt from '@src/auth/jwt/useJwt'
const config = useJwt.jwtConfig
const headers = {
  token: JSON.parse(localStorage.getItem(config.storageTokenKeyName)),
  userID: JSON.parse(localStorage.getItem('userID'))
}
const baseURL = `${process.env.REACT_APP_API_BASE_URL}`
export const addItem = createAsyncThunk('appItem/Create', async (formData) => {
  // http://152.44.42.166:8091
  const response = await axios.post(`${baseURL}/Item/Create`, formData, {mode:'cors'})
  return response
})

export const UpdateItem = createAsyncThunk('appItem/Update', async (formData) => {
  const response = await axios.post(`${baseURL}/Item/Update`, formData, {mode:'cors'})
  return response
})

export const getItemCondition = createAsyncThunk('appItem/getConditionList', async () => {

  const response = await axios.post(`${baseURL}/Common/GetConditionList`, {headers})
  return response.data
})

export const getRentFrequency = createAsyncThunk('appItem/getRentFrequencyList', async () => {

    const response = await axios.post(`${baseURL}/Common/GetRentFrequencyList`, {headers})
    return response.data
})

export const getItemCirclesBorrow = createAsyncThunk('appItem/getItemCirclesBorrow', async (params) => {

  const response = await axios.post(`${baseURL}/Item/GetItemCirclesBorrow`, params, {headers})
  return response.data
})

export const getItemCirclesRent = createAsyncThunk('appItem/getItemCirclesRent', async (params) => {
    
  const response = await axios.post(`${baseURL}/Item/getItemCirclesRent`, params, {headers})
  return response.data
})

export const getItemCirclesBorrowForEdit = createAsyncThunk('appItem/getItemCirclesBorrowForEdit', async (params) => {
  const response = await axios.post(`${baseURL}/Item/GetItemCirclesBorrowForEdit`, params, {headers})
  return response.data
})

export const getItemCirclesRentForEdit = createAsyncThunk('appItem/getItemCirclesRentForEdit', async (params) => {
    
  const response = await axios.post(`${baseURL}/Item/GetItemCirclesRentForEdit`, params, {headers})
  return response.data
})

    
export const getData = createAsyncThunk('appItem/getData', async (params) => {
  const response = await axios.post(`${baseURL}/Item/GetTableSummary`, params, {mode:'cors'})
  return {
    params,
    data: response.data
  }
})

export const resetData = createAsyncThunk('appItem/resetData', async () => {
  return { data: [] }
})

export const getItemDetail = createAsyncThunk('appItem/getItemDetail', async (params) => {
  const response = await axios.post(`${baseURL}/Item/GetItemDetail`, params, {mode:'cors'})
  return {
    params,
    data: response.data
  }
})

export const resetItemDetail = createAsyncThunk('appItem/resetItemDetail', async () => {
  return {
    data: []
  }
})

export const getItemImage = createAsyncThunk('appItem/GetItemImage', async (params) => {
  const response = await axios.post(`${baseURL}/Item/GetItemImage`, params, {mode:'cors'})
  return response.data
})
    
export const getVehicleType = createAsyncThunk('appVehicle/getVehicleType', async () => {
  const response = await axios.post(`${baseURL}/Vehicle/GetVehicleTypeList`, {headers})
  return response.data
})

export const getSubCompany = createAsyncThunk('appVehicle/getSubCompany', async () => {
  const response = await axios.post(`${baseURL}/Common/GetSubCompanyList`, {headers})
  return response.data
})

export const getNationality = createAsyncThunk('appVehicle/getNationality', async () => {
  const response = await axios.get(`${baseURL}/Common/GetNationalityList`, {headers})
  return response.data
})

export const GetVehicleTag = createAsyncThunk('appVehicle/GetVehicleTagList', async () => {
  const response = await axios.post(`${baseURL}/Vehicle/GetVehicleTagList`, {mode:'cors'})
  return response.data
})

export const getAllData = createAsyncThunk('appVehicle/GetTableSummary', async (GetVehicle) => {
  const response = await axios.post(`${baseURL}/Vehicle/GetTableSummary`, GetVehicle, {mode:'cors'})
  return response.data
})
export const getVehicleStats = createAsyncThunk('appVehicle/GetVehicleStatistics', async (GetVehicle) => {
  const response = await axios.post(`${baseURL}/Vehicle/GetVehicleStatistics`, GetVehicle, {mode:'cors'})
  return response.data
})

export const getVehicleBookingCount = createAsyncThunk('Vehicle/GetVehicleBookingCount', async (GetVehicle) => {
  const response = await axios.post(`${baseURL}/Vehicle/GetVehicleBookingCount`, GetVehicle, {mode:'cors'})
  return response.data
})
export const GetDriver = createAsyncThunk('appDriver/GetDriverList', async (GetDriver) => {
  const response = await axios.post(`${baseURL}/Driver/GetDriverList`, GetDriver, {mode:'cors'})
  return response.data
})
    
export const getDocumentTypeList = createAsyncThunk('appDriver/getDocumentTypeList', async (OwnerType) => {
  const response = await axios.post(`${baseURL}/Common/GetDocumentTypeList`, OwnerType, {mode:'cors'})
  return response.data
})

export const deleteDocument = createAsyncThunk('appDriver/DeleteDocument', async (id) => {
  const response = await axios.post(`${baseURL}/Common/DeleteDocument`, id, {mode:'cors'})
  return response
})

export const EditDocument = createAsyncThunk('appDriver/UpdateDocument', async (formData) => {
  let flag = 0
  const response = await axios.post(`${baseURL}/Common/UpdateDocument`, formData, {mode:'cors'})
  if (response.data.code === 200) { flag = 1 }
  return {  response,
            isSaved:flag
  }
})

export const getDocument = createAsyncThunk('appDriver/getDocument', async (Doc) => {
  const response = await axios.post(`${baseURL}/Common/GetDocument`, Doc, {mode:'cors'})
  return response.data
})

export const addDocument = createAsyncThunk('appDriver/CreateDocument', async (formData) => {
  let flag = 0
  const response = await axios.post(`${baseURL}/Common/Create`, formData, {mode:'cors'})
  
  if (response.data.code === 200) {
    flag = 1
  }
  return {  response, isSaved:flag}
})

export const resetFlag = createAsyncThunk('appDriver/ResetFlag', () => {
  return {   isSaved:0  }
})

export const GetDocumentDetailsByOwnerType = createAsyncThunk('appDriver/GetDocumentDetailsByOwner', async (Owner) => {
  const response = await axios.post(`${baseURL}/Common/GetDocumentSummaryByOwnerType`, Owner, {mode:'cors'})
  return response.data
})

export const GetDocumentDetailsByID = createAsyncThunk('appDriver/GetDocumentDetailsByID', async (document) => {
  const response = await axios.post(`${baseURL}/Common/GetDocumentDetailByID`, document, {mode:'cors'})
  return response.data
})

// export const getData = createAsyncThunk('appVehicle/getData', async (params) => {
    
  
//  const response = await axios.post(`${baseURL}/Vehicle/GetTableSummary', params, {mode:'cors'})
        
//       return {
//         params,
//         data: response.data.data,
//         allData: response.data.allData,
//         totalPages: response.data.total
//       }
// })
export const getVehicle = createAsyncThunk('appVehicle/getVehicle', async (params) => {
  const response = await axios.post(`${baseURL}/Vehicle/GetTableSummary`, params, {mode:'cors'})
  return {
    params,
    data: response.data.data,
    allData: response.data.allData,
    totalPages: response.data.total
  }
})

export const EditVehicle = createAsyncThunk('appVehicle/Update', async (Vehicle) => {
  const response = await axios.post(`${baseURL}/Vehicle/Update`, Vehicle, {mode:'cors'})
  return response
})

export const deleteVehicle = createAsyncThunk('appVehicle/Delete', async (id, { dispatch, getState }) => {
  const response = await axios.post(`${baseURL}/Vehicle/Delete`, id, {mode:'cors'})
  await dispatch(getData(getState().Vehicle.params))
  return response
})

export const deleteItem = createAsyncThunk('appItem/Delete', async (id) => {
  const response = await axios.post(`${baseURL}/Item/Delete`, id, {mode:'cors'})
  return response
})

export const addUser = createAsyncThunk('appVehicle/addUser', async (user, { dispatch, getState }) => {
  await axios.post(`${baseURL}/User/CreateUser`, user, {headers}, {mode:'cors'})
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

// export const addUser = createAsyncThunk('appVehicle/addUser', async (user, { dispatch, getState }) => {
//   await axios.post('/apps/users/add-user', user)
//   await dispatch(getData(getState().users.params))
//   await dispatch(getAllData())
//   return user
// })
export const deleteUser = createAsyncThunk('appVehicle/deleteUser', async (id, { dispatch, getState }) => {
  await axios.post(`${baseURL}/User/DeleteUser`, { id }, {headers}, {mode:'cors'})
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const getUser = createAsyncThunk('appUsers/getUser', async () => {
  const response = await axios.get(`${baseURL}/Vendor/GetVendorList`, {headers}, data)
  return response.data
})

export const appItemSlice = createSlice({
  name: 'appItem',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    selectedDocument:[],
    ItemDetailData:[],
    ItemCircleData:[],
    IsSaved:0
  },
  reducers: {},
  extraReducers: builder => {
    builder
      
      .addCase(getData.fulfilled, (state, action) => {
        
        state.data = action.payload.data
        state.ItemCircleData = action.payload.data
      // state.allData = action.payload.allData
      // state.total = action.payload.totalPages
      // state.params = action.payload.params
      })
      .addCase(resetData.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
      .addCase(getItemDetail.fulfilled, (state, action) => {
        
        state.ItemDetailData = action.payload.data
      })
      .addCase(resetItemDetail.fulfilled, (state, action) => {
        
        state.ItemDetailData = action.payload.data
      })
      .addCase(getVehicle.fulfilled, (state, action) => {
        
        state.selectedUser = action.payload.allData[0]
      })
        .addCase(GetDocumentDetailsByID.fulfilled, (state, action) => {
          
        state.selectedDocument = action.payload
  })
  .addCase(addDocument.fulfilled, (state, action) => {
      
    state.IsSaved = action.payload.isSaved
  })
  .addCase(EditDocument.fulfilled, (state, action) => {
      
    state.IsSaved = action.payload.isSaved
  })
  .addCase(resetFlag.fulfilled, (state, action) => {
      
    state.IsSaved = action.payload.isSaved
  })
  
  }
})
  
export default appItemSlice.reducer
