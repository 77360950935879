// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
// import useJwt from '@src/auth/jwt/useJwt'
// const config = useJwt.jwtConfig
// const headers = {
//     token: JSON.parse(localStorage.getItem(config.storageTokenKeyName)),
//     userID: JSON.parse(localStorage.getItem('userID'))
//     }
const baseURL = `${process.env.REACT_APP_API_BASE_URL}`
    export const getUsersList = createAsyncThunk('Client/getUsersList', async () => {
      const response = await axios.post(`${baseURL}/Circle/GetUsersList`)
      return response.data
    })

    export const getMyCircle = createAsyncThunk('Client/getMyCircles', async (params) => {
     
      const response = await axios.post(`${baseURL}/Circle/GetMyCircles`, params)
      
      return response.data
    })

    export const getSubscribedCircles = createAsyncThunk('Client/GetSubscribedCircles', async (params) => {
      const response = await axios.post(`${baseURL}/Circle/GetSubscribedCircles`, params)
      return response.data
    })

    export const getSubscribedCircleItems = createAsyncThunk('Client/GetSubscribedCircle', async (params) => {
      const response = await axios.post(`${baseURL}/Circle/GetSubscribedCircleItems`, params)
      return response.data
    })

    export const getCircleMemberList = createAsyncThunk('appCircle/getCircleMembersList', async (params) => {
     
      const response = await axios.post(`${baseURL}/Circle/GetCircleMembersList`, params)
     
      return response.data
    })

    export const getCirclesList = createAsyncThunk('appCircle/GetCirclesList', async (params) => {
      
      const response = await axios.post(`${baseURL}/Common/GetCirclesList`, params)
      return response.data
    })

    export const getItemCircleList = createAsyncThunk('appCircle/GetItemCirclesList', async (params) => {
      
      const response = await axios.post(`${baseURL}/Circle/getItemCirclesList`, params)
      return response.data
    })

    export const getData = createAsyncThunk('appCircle/getData', async (params) => {
     
      const response = await axios.post(`${baseURL}/Circle/GetTableSummary`, params, {mode:'cors'})
      return {
          params,
          data: response.data.data,
          allData: response.data.allData,
          totalPages: response.data.total
      }
    })

    export const getSubCircleData = createAsyncThunk('appCircle/getSubItemsList', async (params) => {
     
      
       const response = await axios.post(`${baseURL}/Circle/GetSubItemsList`, params, {mode:'cors'})
        
       return {
         params,
         data: response.data
       }
       })

    export const getEditData = createAsyncThunk('appCircle/getEditData', async (params) => {
      
       const response = await axios.post(`${baseURL}/Circle/GetTableSummary`, params, {mode:'cors'})
       return {
           params,
           data: response.data.data,
           allData: response.data.allData,
           totalPages: response.data.total
       }
     })

     export const resetEditData = createAsyncThunk('appCircle/resetEditData', async () => {
      
       return {
           data: []
       }
     })

    export const addCircle = createAsyncThunk('appCircle/Create', async (params) => {
          const response = await axios.post(`${baseURL}/Circle/Create`, params, {mode:'cors'})
          return response
    })

    export const inviteFriend = createAsyncThunk('appCircle/InviteFriend', async (params) => {
      const response = await axios.post(`${baseURL}/Circle/InviteFriend`, params, {mode:'cors'})
      return response
    })  

    export const inviteOrganiztion = createAsyncThunk('appCircle/InviteBusiness', async (params) => {
      const response = await axios.post(`${baseURL}/Circle/InviteBusiness`, params, {mode:'cors'})
      return response
    })

    export const EditCircle = createAsyncThunk('appCircle/Update', async (params) => {
        const response = await axios.post(`${baseURL}/Circle/Update`, params, {mode:'cors'})
        return response
    })

   export const deleteCircle = createAsyncThunk('appCircle/Delete', async (id, { dispatch, getState }) => {
       
    const response = await axios.post(`${baseURL}/Circle/Delete`, id, {mode:'cors'})
        await dispatch(getData(getState().Circle.params))
        return response
    })

    export const setEditID = createAsyncThunk('appCircle/setEditID', async (id) => {
            return {
              data: id
          }
      })

  export const appCircleSlice = createSlice({
    name: 'appCircle',
    initialState: {
      data: [],
      total: 1,
      params: {},
      allData: [],
      editID:0,
      editData:[],
      subCircleData:[]
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getData.fulfilled, (state, action) => {
          
              state.data = action.payload.data
              state.allData = action.payload.allData
              state.total = action.payload.totalPages
              state.params = action.payload.params
        })
        .addCase(setEditID.fulfilled, (state, action) => {
          
          state.editID = action.payload.data.id
        })
        .addCase(getEditData.fulfilled, (state, action) => {
          
          state.editData = action.payload.allData
        })
        .addCase(resetEditData.fulfilled, (state, action) => {
          
          state.editData = action.payload.data
          state.editID = 0
        })
        .addCase(getSubCircleData.fulfilled, (state, action) => {
          state.subCircleData = action.payload.data
        })
    }
  })
  
  export default appCircleSlice.reducer