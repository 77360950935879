// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
// import useJwt from '@src/auth/jwt/useJwt'
// const config = useJwt.jwtConfig
// const headers = {
//     token: JSON.parse(localStorage.getItem(config.storageTokenKeyName)),
//     userID: JSON.parse(localStorage.getItem('userID'))
//     }
const baseURL = `${process.env.REACT_APP_API_BASE_URL}`
export const EditSubscriptionPlan = createAsyncThunk('appEarnings/Update', async (params) => {
  const response = await axios.post(`${baseURL}/SubscriptionPlan/Update`, params, {mode:'cors'})
  return response
})

export const getRentTranscationData = createAsyncThunk('appEarnings/getRentTranscation', async (params) => {
  const response = await axios.post(`${baseURL}/Earnings/GetRentTranscation`, params, {mode:'cors'})
  return {
    params,
    data: response.data.data,
    allData: response.data.allData,
    totalPages: response.data.total
  }
})

export const getBuyTranscationData = createAsyncThunk('appEarnings/getBuyTranscation', async (params) => {
  const response = await axios.post(`${baseURL}/BorrowBucks/GetBuyTranscation`, params, {mode:'cors'})
  return {
      params,
      data: response.data.data,
      allData: response.data.allData,
      totalPages: response.data.total
  }
})

export const getSubscriptionPlanList = createAsyncThunk('appItem/getSubscriptionPlanList', async () => {
  const response = await axios.post(`${baseURL}/Common/GetSubscriptionPlanList`, {mode:'cors'})
  return response.data
})

export const getCurrSubscriptionPlanList = createAsyncThunk('appItem/CurrSubPlanList', async (params) => {
  const response = await axios.post(`${baseURL}/SubscriptionPlan/CurrSubPlanList`, params, {mode:'cors'})
  return response.data
})

export const appSubscriptionPlanSlice = createSlice({
  name: 'appSubscriptionPlan',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    buyTranscationdata: [],
    buyTranscationtotal: 1,
    buyTranscationparams: {},
    buyTranscationallData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRentTranscationData.fulfilled, (state, action) => {
        
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
      })
      .addCase(getBuyTranscationData.fulfilled, (state, action) => {
        
            state.buyTranscationdata = action.payload.data
            state.buyTranscationallData = action.payload.allData
            state.buyTranscationtotal = action.payload.totalPages
            state.buyTranscationparams = action.payload.params
      })
  }
})

export default appSubscriptionPlanSlice.reducer
